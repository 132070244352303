/* for the background color of the form and image */
.iconColor {
	/* font-size: 1rem; */
	width: 100%;
	height: 100%;
	font-size: large;
}
.formPrimary {
	background-color: var(--darkBlue);
	width: 100vw;
	height: 100vh;
}
.formSignUpPrimary {
	background-color: var(--darkBlue);
	width: 60%;
}
/* // for image and form to align flex */
.formSecondary {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.formProfilePrimary {
	display: flex;
	width: 100%;
	background-color: var(--darkBlue);
}
/* // for image and form to align flex */
.formProfileSecondary {
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	align-items: center;
}

/* // image  */
.formSecondary img {
	height: 60vh;
	width: 40vw;
	display: flex;
}
/* main form */

.form {
	width: 70%;
	height: auto;
	margin: 10% 15%;
	border: 2px solid whitesmoke;
	background-color: white;
	padding: 20px;
}

/* //form blog is for big text area input like creatign blogs */
.formBlog {
	width: 50%;
	height: auto;
	margin: 5% 20%;
	border: 2px solid whitesmoke;
	background-color: white;
	padding: 20px;
}

.formProfile {
	height: auto;
	border: 2px solid whitesmoke;
	background-color: white;
	padding: 20px;
	width: 50%;
}

/* // input of form */
.formSecondary input,
.formSecondary textarea {
	padding: 11px 10px;
	border: none;
}

.formSecondary textarea::placeholder,
.formSecondary input::placeholder {
	font-family: initial;
	font-size: initial;
}
/* // focus of input */
.formSecondary input:focus,
.formSecondary textarea:focus {
	outline: none;
}
/* // icon and input together */

.formInput {
	width: 80%;
	background: rgba(31, 35, 35, 0.03);
	display: grid;
	grid-template-columns: 0.1fr 1fr;
	align-items: center;
	/* padding-left: 15px; */
	text-align: center;
	border: 1px solid rgba(31, 94, 180, 0.22);
	border-radius: 4px;
	margin: 30px auto;
}

/* // invalid form input */
.formInputInvalid {
	background-color: var(--red);
	color: white;
}
/* // focusing the whole icon and input when focused upon inpu */
.formInput:focus-within {
	background-color: rgba(51, 12, 158, 0.151);
	border: 1px solid blue;
}

/* // h2 */
.formHeader {
	text-align: center;
	text-transform: uppercase;
	font-size: 24px;
}

.formHeader::after {
	height: 4px;
	display: block;
	content: "";
	width: 120px;
	background-color: var(--mainBlack);
	margin: 20px auto 0 auto;
}

/* // link textxx */
.linkText {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	font-size: 80%;
	margin-top: 20px;
}
/* // for image file button margin */
.formInputImageUploadInput {
	margin-left: 20px;
}

/* // error text */
.errorText {
	color: var(--red);
	/* font-size: 80%; */
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: -25px;
}
.errorText::before {
	/* display: inline; */
	content: "⚠  \00a0  ";
}

@media (max-width: 799px) {
	.form {
		width: 60%;
		height: auto;
		margin: 10% 20%;
		border: 2px solid white;
		background-color: white;
		padding: 20px;
	}
	.formSecondary img {
		display: none;
	}
	.formBlog {
		width: 80%;
		height: auto;
		margin: 10%;
	}
}
@media (max-width: 1025px) {
	.formSecondary img {
		width: 30%;
	}
	.formBlog {
		width: 80%;
		margin: 10%;
	}
	.form {
		width: 100%;
		height: auto;
	}
}
@media (max-width: 600px) {
	.formSecondary img {
		display: none;
	}
	.form {
		border: 2px solid white;
		background-color: white;
		padding: 20px;
	}
	.formInput {
		width: 100%;
	}
	.formBlog {
		width: 90%;
		margin: 5%;
	}
	.formSecondary {
		margin: 0;
	}
}
@media (max-width: 470px) {
	.form {
		border: 2px solid white;
		background-color: white;
		padding: 20px;
		margin: 0;
	}
	.formBlog {
		margin: 2.5%;
		width: 95%;
	}

	.formInput {
		width: 100%;
	}
	.formPrimary {
		height: 70vh;
		padding-bottom: 5rem;
	}
}
