.CategoryItem_main {
  margin-bottom: -30px;
}
.CategoryItem_main_info {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  width: 100%; 
}

 
 

 