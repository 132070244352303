.HeaderTitle {
	height: 100%;
	border: none;
	color: var(--brand);
	text-transform: uppercase;
	font-weight: 700;
	line-height: 140%;
	transition: 0.3s ease;
	padding: 30px 0px;
}
