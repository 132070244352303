.payment {
  width: 80%;
  margin: 30px auto;
}

.paymentTitle {
  color: var(--mainBlack);
  font-size: 1.5rem;
  width: 80%;
  margin: 10px auto;
  text-align: left;
}

.paymentHelp {
}

.paymentUserNotLoggedIn {
  padding: 20px 0 0;
  color: var(--red);
}

.paymentMethodsContainer {
  margin-top: 30px;
  position: relative;
}

.paymentHideMethods {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.7);
}

.paymentMethodsTitle {
  width: 80%;
  margin: 30px auto 10px;
  color: var(--mainBlack);
  font-size: 1.5rem;
  text-align: left;
}

.paymentMethodHelp {
  width: 80%;
  margin: 0 auto;
}

.paymentMethodError {
  width: 80%;
  margin: 0 auto;
  color: var(--red);
}

.paymentMethods {
  width: 80%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--mainBlack);
  padding: 20px 20px 10px;
}
