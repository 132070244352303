.RadiantPlayer {
  /* position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000; */
  width: 100%;
  /* padding: 20px 0; */
  /* margin: 0 auto; */
}
.RadiantPlayer .rmp-control-bar {
  /* margin-top: -200px; */
}
